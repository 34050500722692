import React, { useState } from 'react';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { Link as InternalLink } from 'react-scroll'
import { StaticImage } from 'gatsby-plugin-image'

const DownloadPageContent = () => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    company: '',
    tel: '',
    agree: false,
  });

  const [errors, setErrors] = useState({
    email: '',
    name: '',
    company: '',
    tel: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // 未入力項目について、ブラウザのバリデーションではなくカスタムバリデーションを実装
  const blankCheck = (value) => {
    const character = /\S/; // 空白以外の任意の文字にマッチ
    return character.test(value);
  };

  // 各フィールドに対して個別に未入力項目チェックを行う
  const isEmailValid = blankCheck(formData.email);
  const isNameValid = blankCheck(formData.name);
  const isCompanyValid = blankCheck(formData.company);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatetel = (tel) => {
    // 10〜15桁の数字のみを許可
    const telRegex = /^[0-9]{10,15}$/;
    return telRegex.test(tel);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      // チェックボックスの場合は checked を使用
      [name]: type === 'checkbox' ? checked : value,
      page: 'page1',
    });

    // 入力の変更があったときにエラーメッセージをリセット
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // バリデーションチェック
    let hasError = false;

    if (!isEmailValid || !isNameValid || !isCompanyValid) { setErrors((prevErrors) => ({
      ...prevErrors,
      email: !isEmailValid ? '入力してください。' : prevErrors.email,
      name: !isNameValid ? '入力してください。' : prevErrors.name,
      company: !isCompanyValid ? '入力してください。' : prevErrors.company, }));
      hasError = true;
    }

    if (!validateEmail(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: '正しいメールアドレスを入力してください。',
      }));
      hasError = true;
    }
    if (formData.tel && !validatetel(formData.tel)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tel: '正しい電話番号を入力してください（10〜15桁の数字）。',
      }));
      hasError = true;
    }

    // プライバシーポリシーの同意がない場合は送信を防ぐ
    if (!formData.agree) {
      alert('プライバシーポリシーに同意してください。');
      return;
    }

    // バリデーションにエラーがある場合は送信を中止
    if (hasError) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        window.location.href = '/downloads/downloadpage/';
      } else {
        alert('メール送信に失敗しました。');
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
      alert('メール送信中にエラーが発生しました。');
    } finally {
      setIsSubmitting(false);
    }
  };

  // エラー時にフォーム先頭へスクロールする関数
  const scrollToForm = () => {
    const formElement = document.getElementById('scrollform');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="main">
      <section className="download__section">
        <div className="download__inner download-detail__inner">
          <div className="download-detail__description-area">
            <p className="download-detail__title download-detail__title__bg-blue">
              企業担当者に聞いた！<br/>システム開発に関する調査結果（5点）<br/>おまとめダウンロード
            </p>
            <div className="download-detail__img-wrapper">
              <StaticImage className="download-list__img" src="../../../static/image/downloads/download_bundle_2.png" placeholder="blurred" alt="サムネイル" />
            </div>
            <div className="download-detail__text-wrapper">
              <p className="download-detail__headline">
                資料の概要
              </p>
              <p className="download-detail__text">
                株式会社もばらぶが企業担当者に対して実施した調査結果レポートをまとめてダウンロードできます。ご希望の方は
                <a className="download-detail__PC">
                  右のフォーム
                </a>
                <InternalLink
                  to="scrollform"
                  smooth={true}
                  duration={500}
                  className="download-detail__SP"
                  style={{display: 'none',textDecoration: 'underline'}}>
                    こちらのフォーム
                </InternalLink>
                に必要事項を入力の上、送信して下さい。
              </p>
            </div>
            <div className="download-detail__button-wrapper">
              <a className="btn-purple" href="../../download/">資料一覧ページへ</a>
              <a className="btn-pink" href="https://blog.mobalab.net/inquiry/">お問い合わせフォームへ</a>
            </div>
          </div>
          <div id="scrollform"></div>
          <div className="download-detail__input-form-area">
            <div className="download-detail__input-form-notice-area">
              <span>
                資料ダウンロードはこちら
              </span>
            </div>
            <form className="download-detail__form" onSubmit={handleSubmit}>
              <div className="download-detail__form-item">
                <label className="download-detail__label" htmlFor="company">企業名（無所属の場合は【なし】）<span>必須</span></label>
                <input
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  placeholder="例)なし"
                  // required //ブラウザのバリデーションを無効にするためコメントアウト
                  aria-required="true"
                  className="download-detail__inputbox"
                />
                {/* {errors.company && <div className="download-detail__error">{errors.company}</div>} */}
                <div className="download-detail__error">
                  {errors.company ? errors.company : ''}
                </div>
                {errors.company && scrollToForm()}
              </div>
              <div className="download-detail__form-item">
                <label className="download-detail__label" htmlFor="name">お名前<span>必須</span></label>
                <input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="例)茂原　部美"
                  // required //ブラウザのバリデーションを無効にするためコメントアウト
                  aria-required="true"
                  className="download-detail__inputbox"
                />
                {/* {errors.name && <div className="download-detail__error">{errors.name}</div>} */}
                <div className="download-detail__error">
                  {errors.name ? errors.name : ''}
                </div>
                {errors.name && scrollToForm()}
              </div>
              <div className="download-detail__form-item">
                <label className="download-detail__label" htmlFor="email">メールアドレス<span>必須</span></label>
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="例)sample@email.com"
                  // required //ブラウザのバリデーションを無効にするためコメントアウト
                  aria-required="true"
                  className="download-detail__inputbox"
                />
                {/* {errors.email && <div className="download-detail__error">{errors.email}</div>} */}
                <div className="download-detail__error">
                  {errors.email ? errors.email : ''}
                </div>
                {errors.email && scrollToForm()}
              </div>
              <div className="download-detail__form-item">
                <label className="download-detail__label" htmlFor="tel">お電話番号（数字のみ。ハイフン不要）</label>
                <input
                  name="tel"
                  value={formData.tel}
                  onChange={handleChange}
                  placeholder="例)01234567890"
                  className="download-detail__inputbox"
                />
                {/* {errors.tel && <div className="download-detail__error">{errors.tel}</div>} */}
                <div className="download-detail__error download-detail__error-long">
                  {errors.tel ? errors.tel : ''}
                </div>
                {errors.tel && scrollToForm()}
              </div>
              {/* プライバシーポリシーのチェックボックス */}
              <div className="download-detail__agree">
                <input
                  type="checkbox"
                  name="agree"
                  checked={formData.agree}
                  onChange={handleChange}
                  required
                  className="download-detail__checkbox"
                />
                <a href="/downloads/privacy-policy/" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>に同意する
              </div>
              <button
                className="btn-white"
                type="submit"
                disabled={isSubmitting} // 送信中はボタンを無効化
              >
                {isSubmitting ? '送信中...' : '送信'}
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

const DownloadPage = (props) => (
  <Layout
  title="企業担当者に聞いた！システム開発に関する調査結果 | まとめてダウンロード | 株式会社もばらぶ"
  description="企業担当者の声をもとにしたシステム開発に関する最新調査結果をお届けします。費用、選定基準、成功・失敗事例などの調査データを一括ダウンロード可能！"
  pathName={props.location.pathname}
  >
    <Header />
    <DownloadPageContent />
  </Layout>
);

export default DownloadPage;
